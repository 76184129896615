var render = function render(){
  var _vm$countriesMap$_vm$, _vm$riskLevelLegend, _vm$riskLevelLegend2, _vm$riskLevelLegend7, _vm$riskLevelLegend8, _vm$startLocationWeat, _vm$startLocationWeat2, _vm$startLocationWeat3, _vm$startLocationWeat4, _vm$startLocationWeat5, _vm$startLocationWeat6, _vm$startLocationWeat7, _vm$startLocationWeat8;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._b({
    staticClass: "width h-100",
    attrs: {
      "body-class": _vm.bodyClass
    }
  }, 'div', _vm.bCardBindings, false), [_c('b-overlay', {
    attrs: {
      "rounded": "sm",
      "show": _vm.isLoading
    }
  }, [_vm.countryIso && !_vm.isLoading ? _c('div', [_vm.countryMaxRiskLevel && _vm.countryMaxRiskLevel.max_risk > 0 ? _c('div', [_c('risk-map-date-alert', {
    attrs: {
      "date": _vm.currentDay,
      "country-name": (_vm$countriesMap$_vm$ = _vm.countriesMap[_vm.countryIso]) === null || _vm$countriesMap$_vm$ === void 0 ? void 0 : _vm$countriesMap$_vm$.name
    }
  }), _c('div', {
    staticClass: "separator separator-dashed my-5"
  }), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "font-weight-bold text-capitalize mr-3"
  }, [_vm._v(_vm._s(_vm.$t("Max risk level procedure")) + ":")]), _c('rectangle', {
    attrs: {
      "label": (_vm$riskLevelLegend = _vm.riskLevelLegend[_vm.countryMaxRiskLevel.max_risk - 1]) === null || _vm$riskLevelLegend === void 0 ? void 0 : _vm$riskLevelLegend.label,
      "color": (_vm$riskLevelLegend2 = _vm.riskLevelLegend[_vm.countryMaxRiskLevel.max_risk - 1]) === null || _vm$riskLevelLegend2 === void 0 ? void 0 : _vm$riskLevelLegend2.color
    }
  })], 1), _c('div', {
    staticClass: "separator separator-dashed my-5"
  }), _c('div', [_c('span', {
    staticClass: "d-inline-block font-weight-bold text-capitalize mb-1"
  }, [_vm._v(_vm._s(_vm.$t("Risk from other Countries")) + ":")]), _c('div', _vm._l(_vm.countryMaxRiskLevel.max_risk_data, function (risk, idx) {
    var _vm$riskLevelLegend3, _vm$countriesMap$risk, _vm$riskLevelLegend4;
    return _c('rectangle', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      key: idx,
      staticClass: "m-1 rectangle-item",
      attrs: {
        "title": (_vm$riskLevelLegend3 = _vm.riskLevelLegend[risk.risk - 1]) === null || _vm$riskLevelLegend3 === void 0 ? void 0 : _vm$riskLevelLegend3.label,
        "label": (_vm$countriesMap$risk = _vm.countriesMap[risk.country]) === null || _vm$countriesMap$risk === void 0 ? void 0 : _vm$countriesMap$risk.name,
        "color": (_vm$riskLevelLegend4 = _vm.riskLevelLegend[risk.risk - 1]) === null || _vm$riskLevelLegend4 === void 0 ? void 0 : _vm$riskLevelLegend4.color
      }
    });
  }), 1)]), _c('div', {
    staticClass: "separator separator-dashed my-5"
  })], 1) : _vm._e(), _vm._l(_vm.statistics, function (statistic, idx) {
    return _c('div', {
      key: idx,
      staticClass: "d-flex align-items-center justify-content-between pb-2"
    }, [_c('div', {
      staticClass: "font-weight-bold text-capitalize"
    }, [_vm._v(" " + _vm._s(statistic.label) + ": ")]), _c('div', {
      staticClass: "text-right font-weight-bold"
    }, [typeof statistic.value == 'number' ? _c('span', {
      staticClass: "font-weight-bolder",
      class: [statistic.value > 0 ? 'text-success' : 'text-danger']
    }, [_vm._v(" " + _vm._s(statistic.value > 0 ? "+".concat(statistic.value) : statistic.value) + " ")]) : _c('span', [_vm._v(" " + _vm._s(statistic.value) + " ")])])]);
  }), _c('div', {
    staticClass: "separator separator-dashed mt-2 mb-5"
  }), _vm.riskMapAvailability ? _c('div', [_c('button', {
    staticClass: "btn btn-block btn-light-primary font-weight-bolder text-uppercase",
    on: {
      "click": _vm.openTravelAdvice
    }
  }, [_c('i', {
    class: _vm.icons.map
  }), _vm._v(" " + _vm._s(_vm.$t("Risk Map Advice")) + " ")])]) : _c('div', [_c('button', {
    staticClass: "btn btn-block btn-success font-weight-bolder text-uppercase",
    on: {
      "click": function click($event) {
        return _vm.addRiskMapInCart();
      }
    }
  }, [_c('i', {
    staticClass: "fas fa-cart-arrow-down"
  }), _vm._v(" " + _vm._s(_vm.$t("Buy Risk Map")) + " ")])]), !_vm.isOnlyMaps ? _c('div', [_vm.reportAvailability ? _c('div', [_c('router-link', {
    staticClass: "btn btn-block btn-light-primary font-weight-bolder text-uppercase mt-3",
    attrs: {
      "to": {
        name: 'reports',
        params: _vm.countryIso,
        query: {
          iso: _vm.countryIso
        }
      }
    }
  }, [_c('i', {
    class: _vm.icons.document
  }), _vm._v(" " + _vm._s(_vm.$t("SHOW REPORT")) + " ")])], 1) : _c('div', [_c('router-link', {
    staticClass: "btn btn-block btn-success font-weight-bolder text-uppercase mt-3",
    attrs: {
      "to": {
        name: 'reports',
        params: _vm.countryIso,
        query: {
          iso: _vm.countryIso
        }
      }
    }
  }, [_c('i', {
    staticClass: "fas fa-cart-arrow-down"
  }), _vm._v(" " + _vm._s(_vm.$t("Buy Reports")) + " ")])], 1)]) : _vm._e()], 2) : _vm._e()]), !_vm.countryIso && _vm.showEmptyMessage ? _c('div', {
    staticClass: "font-weight-bold h4 text-center px-6 py-8"
  }, [_vm._v(" " + _vm._s(_vm.$t("Select a country for more information")) + " ")]) : _vm._e(), _vm.riskMapAvailability ? _c('b-modal', {
    attrs: {
      "id": _vm.modalId,
      "size": "xl",
      "hide-footer": ""
    },
    on: {
      "hidden": _vm.closeTravelAdvice
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn() {
        var _vm$riskLevelLegend5, _vm$riskLevelLegend6;
        return [_c('div', {
          staticClass: "w-100 h-100 d-flex justify-content-between align-items-start"
        }, [_c('div', [_c('h3', {
          staticClass: "mb-3 mt-1 font-weight-bolder text-uppercase"
        }, [_vm._v(" " + _vm._s(_vm.$t("Risk Map Advice")) + " " + _vm._s(_vm.countriesMap[_vm.countryIso].name) + " ")]), _vm._l(_vm.statistics, function (statistic, idx) {
          return _c('div', {
            key: idx,
            staticClass: "d-flex align-items-center justify-content-left pb-2"
          }, [_c('i', {
            staticClass: "mr-3",
            class: [statistic.icon, statistic.iconClass]
          }), _c('div', {
            staticClass: "font-weight-bolder text-capitalize"
          }, [_vm._v(_vm._s(statistic.label) + ":")]), _c('div', {
            staticClass: "text-right font-weight-bold ml-3",
            class: statistic.class
          }, [typeof statistic.value == 'number' ? _c('span', {
            staticClass: "font-weight-bolder",
            class: [statistic.value > 0 ? 'text-success' : 'text-danger']
          }, [_vm._v(" " + _vm._s(statistic.value > 0 ? "+".concat(statistic.value) : statistic.value) + " ")]) : _c('span', [_vm._v(" " + _vm._s(statistic.value) + " ")])])]);
        }), _vm.countryMaxRiskLevel && _vm.countryMaxRiskLevel.max_risk > 0 ? _c('div', {
          staticClass: "mb-0"
        }, [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('span', {
          staticClass: "font-weight-bolder text-capitalize mr-2"
        }, [_vm._v(" " + _vm._s(_vm.$t("General Max Risk Level Procedure of Country")) + ": ")]), _c('rectangle', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          attrs: {
            "title": _vm.riskLevelLegend[_vm.countryMaxRiskLevel.max_risk - 1],
            "label": (_vm$riskLevelLegend5 = _vm.riskLevelLegend[_vm.countryMaxRiskLevel.max_risk - 1]) === null || _vm$riskLevelLegend5 === void 0 ? void 0 : _vm$riskLevelLegend5.label,
            "color": (_vm$riskLevelLegend6 = _vm.riskLevelLegend[_vm.countryMaxRiskLevel.max_risk - 1]) === null || _vm$riskLevelLegend6 === void 0 ? void 0 : _vm$riskLevelLegend6.color
          }
        })], 1)]) : _vm._e()], 2), _c('div', {
          staticClass: "d-flex align-items-center"
        }, [!_vm.isOnlyMaps ? _c('router-link', {
          staticClass: "btn btn-block btn-success font-weight-bolder text-uppercase mr-3",
          attrs: {
            "to": {
              name: 'reports',
              params: _vm.countryIso,
              query: {
                iso: _vm.countryIso
              }
            }
          }
        }, [_c('i', {
          staticClass: "fas fa-cart-arrow-down"
        }), _vm._v(" " + _vm._s(_vm.$t("Report Request")) + " ")]) : _vm._e(), _c('b-button', {
          on: {
            "click": _vm.closeTravelAdvice
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")])], 1)])];
      },
      proxy: true
    }], null, false, 358400615)
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "lg": "12"
    }
  }, [_c('p', {
    staticClass: "font-weight-bold overlay-label"
  }, [_vm._v(_vm._s(_vm.$t("Map data overlay")))]), _c('ul', {
    staticClass: "button-list"
  }, [_c('li', {
    staticClass: "mt-0"
  }, [_c('button', {
    staticClass: "btn btn-secondary font-weight-bolder text-uppercase mt-3 btn-sm btn-overlay",
    on: {
      "click": _vm.toggleRiskOverlay
    }
  }, [_c('i', {
    class: _vm.riskOverlay ? _vm.icons.check_circle : _vm.icons.circle
  }), _vm._v(" " + _vm._s(_vm.$t("Risk map")) + " ")])]), _c('li', {
    staticClass: "mt-0"
  }, [_c('button', {
    staticClass: "btn btn-secondary font-weight-bolder text-uppercase mt-3 btn-sm btn-overlay",
    on: {
      "click": _vm.showNaturalDisasters
    }
  }, [_c('i', {
    class: _vm.naturalDisastersOverlay ? _vm.icons.check_circle : _vm.icons.circle
  }), _vm._v(" " + _vm._s(_vm.$t("Natural disasters")) + " ")])])]), _c('google-maps-map', {
    staticClass: "border",
    attrs: {
      "height": "400px",
      "geocoder-options": _vm.gmapGeocoder,
      "kml-layers-urls": _vm.kmlUrl,
      "markers": _vm.markers,
      "directions": _vm.directions,
      "map-options": _vm.map_options,
      "geo-json-layers": _vm.geoJsonLayers
    },
    on: {
      "kml-click": _vm.onKmlClick
    }
  }), _c('ul', {
    staticClass: "level-legend mt-4"
  }, _vm._l(_vm.riskLevelLegend, function (levelLegend, idx) {
    return _c('li', {
      key: idx,
      staticClass: "d-flex align-items-center pl-3"
    }, [_c('rectangle', {
      attrs: {
        "label": levelLegend.label,
        "color": levelLegend.color
      }
    }), _c('span', {
      staticClass: "mr-2 font-weight-normal"
    }, [_vm._v(_vm._s(levelLegend.text))])], 1);
  }), 0)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "mb-1"
  }, [_c('i', {
    staticClass: "pr-1",
    class: _vm.icons.map
  }), _vm._v(" " + _vm._s(_vm.$t("Pointer location")) + ": ")]), _c('google-maps-autocomplete', {
    attrs: {
      "autocomplete-options": _vm.autocompleteOptions
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('b-btn', {
          attrs: {
            "variant": "primary",
            "disabled": !_vm.canAddDestinations
          },
          on: {
            "click": _vm.addDestination
          }
        }, [_c('i', {
          class: _vm.icons.add
        })])];
      },
      proxy: true
    }, {
      key: "pointer-location",
      fn: function fn() {
        return [_vm.autocompleteStartLocation == null ? _c('div', [_c('div', {
          staticClass: "text-muted small"
        }, [_vm._v(_vm._s(_vm.$t("Enter location or click on the map to see more information")))])]) : _vm._e()];
      },
      proxy: true
    }], null, false, 141971783),
    model: {
      value: _vm.autocompleteStartLocation,
      callback: function callback($$v) {
        _vm.autocompleteStartLocation = $$v;
      },
      expression: "autocompleteStartLocation"
    }
  }), _vm._l(_vm.destinations, function (destination, idx) {
    return _c('google-maps-autocomplete', {
      key: destination.key,
      attrs: {
        "label": idx == _vm.destinations.length - 1 ? 'Destination' : 'Waypoint',
        "autocomplete-options": _vm.autocompleteOptions
      },
      scopedSlots: _vm._u([{
        key: "append",
        fn: function fn() {
          return [_c('b-btn', {
            attrs: {
              "variant": "danger"
            },
            on: {
              "click": function click($event) {
                return _vm.removeDestination(destination.key);
              }
            }
          }, [_c('i', {
            class: _vm.icons.delete
          })])];
        },
        proxy: true
      }], null, true),
      model: {
        value: destination.place,
        callback: function callback($$v) {
          _vm.$set(destination, "place", $$v);
        },
        expression: "destination.place"
      }
    });
  })], 2), _c('b-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_vm.startLocationRisk ? _c('div', [_c('div', {
    staticClass: "d-flex align-items-center mb-4"
  }, [_c('div', {
    staticClass: "font-weight-bold mr-2"
  }, [_vm._v(" " + _vm._s(_vm.$t("Selected location max risk level")) + ": ")]), _c('b-overlay', {
    attrs: {
      "rounded": "sm",
      "show": _vm.startLocationRiskLoading,
      "opacity": 0
    }
  }, [_vm.startLocationRisk ? _c('div', {
    staticClass: "d-flex justify-content-end font-weight-bold"
  }, [_c('rectangle', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "label": (_vm$riskLevelLegend7 = _vm.riskLevelLegend[_vm.startLocationMaxRiskStatus - 1]) === null || _vm$riskLevelLegend7 === void 0 ? void 0 : _vm$riskLevelLegend7.label,
      "title": _vm.riskLevelLegend[_vm.startLocationMaxRiskStatus - 1],
      "color": (_vm$riskLevelLegend8 = _vm.riskLevelLegend[_vm.startLocationMaxRiskStatus - 1]) === null || _vm$riskLevelLegend8 === void 0 ? void 0 : _vm$riskLevelLegend8.color
    }
  })], 1) : _vm._e()])], 1), _vm.startLocationRisk && _vm.startLocationMaxRiskCountries.length ? _c('div', {
    staticClass: "mt-5"
  }, _vm._l(_vm.startLocationMaxRiskCountries, function (country) {
    var _vm$riskLevelLegend9;
    return _c('rectangle', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      key: country.countryIso,
      staticClass: "mb-2",
      attrs: {
        "color": (_vm$riskLevelLegend9 = _vm.riskLevelLegend[country.value - 1]) === null || _vm$riskLevelLegend9 === void 0 ? void 0 : _vm$riskLevelLegend9.color,
        "label": _vm.countriesMap[country.countryIso].name
      }
    });
  }), 1) : _vm._e()]) : _vm._e()]), _c('b-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_vm.startLocationWeather ? _c('div', [_c('div', [_vm._v(_vm._s(_vm.$t("Selected location weather")) + ":")]), _c('div', [_c('b-overlay', {
    attrs: {
      "rounded": "sm",
      "show": _vm.startLocationWeatherLoading,
      "opacity": 0
    }
  }, [_vm.startLocationWeather ? _c('div', {
    staticClass: "font-weight-bold"
  }, [_c('img', {
    attrs: {
      "src": "/media/weather/icons/".concat((_vm$startLocationWeat = _vm.startLocationWeather) === null || _vm$startLocationWeat === void 0 ? void 0 : _vm$startLocationWeat.WeatherIcon, ".svg")
    }
  }), _vm._v(" " + _vm._s((_vm$startLocationWeat2 = _vm.startLocationWeather) === null || _vm$startLocationWeat2 === void 0 ? void 0 : _vm$startLocationWeat2.WeatherText) + " " + _vm._s((_vm$startLocationWeat3 = _vm.startLocationWeather) === null || _vm$startLocationWeat3 === void 0 ? void 0 : (_vm$startLocationWeat4 = _vm$startLocationWeat3.Temperature) === null || _vm$startLocationWeat4 === void 0 ? void 0 : (_vm$startLocationWeat5 = _vm$startLocationWeat4.Metric) === null || _vm$startLocationWeat5 === void 0 ? void 0 : _vm$startLocationWeat5.Value) + "°" + _vm._s((_vm$startLocationWeat6 = _vm.startLocationWeather) === null || _vm$startLocationWeat6 === void 0 ? void 0 : (_vm$startLocationWeat7 = _vm$startLocationWeat6.Temperature) === null || _vm$startLocationWeat7 === void 0 ? void 0 : (_vm$startLocationWeat8 = _vm$startLocationWeat7.Metric) === null || _vm$startLocationWeat8 === void 0 ? void 0 : _vm$startLocationWeat8.Unit) + " ")]) : _vm._e()])], 1)]) : _vm._e(), _vm.airQuality ? _c('div', {
    staticClass: "separator separator-dashed my-0"
  }) : _vm._e(), _vm.airQuality ? _c('div', [_c('div', {
    staticClass: "d-flex align-items-center mt-2"
  }, [_c('div', [_c('i', {
    staticClass: "fas fa-smog"
  }), _c('span', {
    staticClass: "ml-2"
  }, [_vm._v(_vm._s(_vm.$t("Pollution Index")) + ":")])]), _c('rectangle', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "ml-4",
    attrs: {
      "title": _vm.airQuality.pollution_description,
      "color": _vm.airQualityColor,
      "label": _vm.airQuality.aqi + ''
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-items-center mt-2"
  }, [_c('div', [_c('i', {
    staticClass: "fas fa-road"
  }), _c('span', {
    staticClass: "ml-2"
  }, [_vm._v(_vm._s(_vm.$t("Distance from station")) + ":")])]), _c('div', {
    staticClass: "ml-2"
  }, [_vm._v(_vm._s(_vm.airQuality.distance_from_station) + " Km")])])]) : _vm._e()]), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "pre-assessment"
  }, [_c('h2', [_vm._v(_vm._s(_vm.$t("Country Assessment")))]), _vm.showPerAssessment ? _c('div', {
    staticClass: "pre-content"
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.convertMarkdownToHtml(_vm.contentPerAssessment))
    }
  }), _c('h2', [_vm._v(_vm._s(_vm.$t("Vaccinations & Risk Categories")))]), _c('div', {
    staticClass: "row"
  }, [_vm.mandatoryVaccinations.length || _vm.recommendedVaccinations.length ? _c('div', {
    staticClass: "col"
  }, [_vm.mandatoryVaccinations.length ? _c('div', {
    staticClass: "mb-6"
  }, [_c('h3', [_vm._v(_vm._s(_vm.$t("Mandatory Vaccinations:")))]), _vm._l(_vm.mandatoryVaccinations, function (mandatoryVaccination) {
    return _c('div', {
      key: mandatoryVaccination
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('span', {
      staticClass: "mb-2 badge badge-warning"
    }, [_vm._v(_vm._s(mandatoryVaccination))])])]);
  })], 2) : _vm._e(), _vm.recommendedVaccinations.length ? _c('div', [_c('h3', [_vm._v(_vm._s(_vm.recommendedVaccinations.length) + " " + _vm._s(_vm.$t("Recommended Vaccinations:")))]), _vm._l(_vm.recommendedVaccinations, function (recommendedVaccination) {
    return _c('div', {
      key: recommendedVaccination
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('span', {
      staticClass: "mb-2 badge badge-info"
    }, [_vm._v(_vm._s(recommendedVaccination))])])]);
  })], 2) : _vm._e()]) : _vm._e(), _vm.categoriesOfRisks.length ? _c('div', {
    staticClass: "col"
  }, [_c('h3', [_vm._v(_vm._s(_vm.$t("Risk Categories:")))]), _vm._l(_vm.categoriesOfRisks, function (categorieRisk) {
    return _c('div', {
      key: categorieRisk.id
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('span', {
      staticClass: "mb-2 badge badge-secondary"
    }, [_vm._v(_vm._s(categorieRisk.name))])])]);
  })], 2) : _vm._e()])]) : _c('div', [_c('span', {
    staticClass: "pr-4"
  }, [_vm._v(_vm._s(_vm.$t("Request pre-assessment of the country")))]), _c('button', {
    staticClass: "btn btn-success font-weight-bolder text-uppercase",
    on: {
      "click": _vm.requestPerAssessment
    }
  }, [_c('span', {
    staticClass: "svg-icon svg-icon-md"
  }, [_c('inline-svg', {
    attrs: {
      "src": "/media/svg/icons/General/Binocular.svg"
    }
  })], 1), _vm._v(" " + _vm._s(_vm.$t("Request Pre Assessment")) + " ")])])])])], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }